.footercontainer{
    background-color: #fff;
    padding: 40px 130px;
}
.footer_logo img{
    width: 80%;
    height: 80%;
}
@media screen and (max-width:800px) {
    .footer_logo img{
        width: 40%;
        height: 40%;
    }
}
@media screen and (max-width:600px) {
    .footer_logo img{
        width: 60%;
        height: 60%;
    }
}
.navlink{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    transition: .3s;
}
.navlink:hover{
    color:var(--secondary)
}
.footericon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border:2px solid #808080;
    color:#808080;
    transition: .3s;
    cursor: pointer;
}
.footericon:hover{
    color:#eac435;
    border-color: #eac435;
}