.app_header {
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 10;
    transition: all 0.2s;
    width: 100%;
    padding: 5px 80px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    direction: ltr !important;
}
.sidenavcontainer {
    background: rgb(255, 255, 255);
    width: 90vw !important;
    position: fixed;

    padding-bottom: 15vh;
    min-height: 100vh !important;
}
@media screen and (max-width: 800px) {
    .app_header {
        padding: 18px 10px;
        position: relative !important;
        direction: rtl !important;
    }
}
@media screen and (max-width: 600px) {
    .app_header {
        padding: 20px 0px;
        position: relative !important;
    }
}
.app_header_scrolled {
    box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
    -webkit-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
    -moz-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
    background-color: white !important;
}
.app_header .app_header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    height: 60px;
}
.header_logo {
    display: flex;
    transition: width 0.2s;
    width: 50%;
}
.header_logo img {
    width: 100%;
}
.generalhomebutton {
    /* width: 230px; */
    height: 45px;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.3s;
    font-family: Poppins-Light;
}
@media (max-width: 991.98px) {
    .app_header__logo img {
        width: 70%;
    }
    .app_header {
        justify-content: space-between;
    }
}
@media screen and (max-width: 800px) {
    .header_logo {
        display: flex;
        transition: width 0.2s;
        width: 70%;
    }
    .header_logo img {
        width: 100%;
    }
}
@media screen and (max-width: 500px) {
    .header_logo {
        width: 90%;
    }
    .generalhomebutton {
        width: 100% !important;
        height: 50px !important;
        font-size: 20px;
    }
}
.navlink {
    color: var(--primary);
    cursor: pointer;
    margin: 0 20px;
    transition: 0.3s;
    /* text-transform: capitalize; */
    position: relative;
}
.navlink:hover {
    color: var(--secondary);
}
.navlink:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
    width: 6px;
    height: 5px;
    border-radius: 50%;
    background: var(--secondary);
    opacity: 0;
    transition: 0.3s;
}
.navlink:hover:after {
    opacity: 1;
}
.navlinkactive {
    color: var(--secondary);
}
.navlinkactive.navlink:after {
    opacity: 1;
}
.languagecontainer {
    background-color: white;
    width: 70px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    transition: 0.3s;
    /* box-shadow: 0 0 10px #e6e6e6; */
}
.languagecontainer:hover {
    color: var(--secondary);
}
@media screen and (max-width: 800px) {
    .languagecontainer {
        width: 50px !important;
        background-color: rgb(255, 255, 255, 0.6) !important;
        backdrop-filter: blur(5px) brightness(200%) !important;
        box-shadow: none !important;
    }
}

.loginbtn {
    border-bottom: 1px solid var(--primary);
    cursor: pointer;
    transition: 0.3s;
}
.loginbtn:hover {
    border-color: var(--secondary);
}
.loggedinicon {
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    border: 2px solid #022648;
    transition: 0.3s;
}
.loggedincontainer:hover .loggedinicon {
    border-color: var(--secondary) !important;
}
/* Create you website button */
.createwebsitebtn {
    text-align: center;
    z-index: 1;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: #b49010;
    font-size: 16px;
    background-color: transparent;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
}
@media screen and (max-width: 600px) {
    .createwebsitebtn {
        font-size: 12px !important;
        padding: 10px 20px;
    }
}
.createwebsitebtn:before {
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--secondary);
    border-radius: 10px;
}
.createwebsitebtn:after {
    content: '';
    z-index: -2;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 30px;
}
.btnblobputercont svg {
    position: absolute;
}
.createwebsitebtn:hover {
    color: #ffffff;
    border-radius: 30px;
}
.createwebsitebtn:hover:after {
    transition: all 0.3s;
    left: 0;
    top: 0;
    border-radius: 30px;
}
.createwebsitebtn__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #ffffff;
}
.createwebsitebtn__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url('#goo');
}
.createwebsitebtn__blob {
    position: absolute;
    top: 2px;
    width: 25%;
    height: 100%;
    background: var(--secondary);
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;
}
@supports (filter: url('#goo')) {
    .createwebsitebtn__blob {
        transform: translate3d(0, 150%, 0) scale(1.4);
    }
}
.createwebsitebtn__blob:nth-child(1) {
    left: 0%;
    transition-delay: 0s;
}
.createwebsitebtn__blob:nth-child(2) {
    left: 30%;
    transition-delay: 0.08s;
}
.createwebsitebtn__blob:nth-child(3) {
    left: 60%;
    transition-delay: 0.16s;
}
.createwebsitebtn__blob:nth-child(4) {
    left: 90%;
    transition-delay: 0.24s;
}
.createwebsitebtn:hover .createwebsitebtn__blob {
    transform: translateZ(0) scale(1.7);
}
@supports (filter: url('#goo')) {
    .createwebsitebtn:hover .createwebsitebtn__blob {
        transform: translateZ(0) scale(1.4);
    }
}
@media screen and (max-width: 800px) {
    .createwebsitebtn {
        /* border-radius: 5px !important; */
    }
}
