/* home */
.home_introtext {
    font-size: 30px !important;
    line-height: 40px;
    font-family: Poppins-Bold;
    /* background: linear-gradient(to left, var(--primary), blue); */
    /* -webkit-text-fill-color: transparent; */
    /* -webkit-background-clip: text; */
}
.home_introtext2 {
    font-size: 20px;
    font-weight: 600;
    color: var(--info);
}
.pricebubble_ar {
    position: absolute;
    top: -45px;
    left: 30%;
    max-width: 20%;
    transform: rotate(-18deg) !important;
}
.pricebubble_en {
    position: absolute;
    top: -45px;
    right: 29%;
    max-width: 20%;
    transform: rotate(18deg) !important;
}
@media screen and (max-width: 800px) {
    .pricebubble_ar {
        position: absolute;
        top: -160px !important;
        left: 0;
        max-width: 50%;
        transform: rotate(-10deg) !important;
    }
    .pricebubble_en {
        position: absolute;
        top: -160px !important;
        right: 0;
        max-width: 50%;
        transform: rotate(10deg) !important;
    }
}
@media screen and (max-width: 500px) {
    .home_introtext {
        font-size: 30px;
    }
    .pricebubble_ar {
        position: absolute;
        top: -70px !important;
        left: -20px;
        max-width: 50%;
        transform: rotate(-10deg) !important;
    }
    .pricebubble_en {
        position: absolute;
        top: -70px !important;
        right: -20px;
        max-width: 50%;
        transform: rotate(10deg) !important;
    }
}
.generalhomebutton {
    /* width: 230px; */
    height: 45px;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.3s;
    font-family: Poppins-Light;
}
.generalhomebutton2 {
    /* width: 230px; */
    height: 45px;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.3s;
    font-family: Poppins-Light;
    border: 1px solid var(--primary);
    color: var(--primary);
}
.generalhomebutton2:hover {
    border: 1px solid var(--secondary);
    color: var(--secondary);
    transition: all 0.4s;
}
.sectionthreeimagecard {
    background-color: white;
    /* border-radius: 50px; */
    width: 100%;
    height: 60vh;
    /* border-radius: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border:2px solid #e6e6e6 */
    /* box-shadow: 0 0 10px #ccc; */
}
.container {
    border-radius: 64px;
    background-color: #ebc3363d;
}

.footericon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #808080;
    color: #808080;
    transition: 0.3s;
    cursor: pointer;
}
.footericon:hover {
    color: #eac435;
    border-color: #eac435;
}
@media screen and (max-width: 600px) {
    .sectionthreeimagecard {
        height: auto;
    }
    .container {
        border-radius: 14px;
        background-color: #ebc3363d;
    }
    .generalhomebutton {
        width: 100% !important;
        height: 50px !important;
        font-size: 17px;
    }
    .generalhomebutton2 {
        width: 100% !important;
        height: 50px !important;
        font-size: 17px;
    }
}
.sectionthreeiconcont {
    width: 5vh;
    height: 5vh;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.sectionfourcardtext {
    width: 100%;
    border-radius: 30px;
    box-shadow: 0 0 10px #e6e6e6;
    padding: 30px 10px;
    background-color: white;
}
.sectionfourcardimageeven {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 60vh;
}
.sectionfourcardtextone {
    transform: translateX(-100px);
}
.sectionfourcardtextonetrans {
    transform: translateX(100px);
}
.sectionfourcardtexttwo {
    transform: translateX(120px);
}
.sectionfourcardtexttwotrans {
    transform: translateX(-120px);
}
@media screen and (max-width: 800px) {
    .sectionfourcardtextone,
    .sectionfourcardtexttwo {
        transform: translateX(0);
    }
}
.pricingcard {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
}
.pricingcard:hover {
    transform: scale(1.05);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}
.pricingcard p {
    font-size: 25px;
}
.pricingcard_price {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}
.pricingcard_price sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
}
.pricingcard_price span {
    color: #bababa;
    font-size: 16px;
    font-weight: 500;
}
.pricingcard ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 25px;
}
.pricingcard ul li {
    padding-bottom: 10px;
}
.pricing_na {
    color: #ccc;
    text-decoration: line-through;
}
/*  */
.whyusimage {
    max-width: 60%;
    max-height: 60%;
}
.pricingcurrencycontainer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}
